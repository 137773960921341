
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import base from "@/api/base.js";

export default defineComponent({
  name: "check-items-detail",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      checkItem: { detail: [] },
      checkItems: [],
      qscList: [{}],
      detailCheckItem: {
        brand: {},
        large_ctgry: {},
        middle_ctgry: {},
        small_ctgry: {},
        detail: [],
        results: [],
      },
      codeList: [],
      sessionId: "",
    };
  },
  // props: { id: String },

  created() {
    base
      .getCodeList()
      .then((res) => {
        console.log(res);
        this.codeList = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  computed: {
    getCodeNm() {
      const item = this.detailCheckItem;
      const code = this.codeList;
      const nm = code
        .filter((list) => list.base_code == item.check_item_ty)
        .map((list) => list.code_title);
      console.log(nm);
      return nm;
    },
  },

  mounted() {
    this.sessionId = sessionStorage.getItem("id");
    console.log(this.sessionId);
    this.getData();
  },
  methods: {
    async editData() {
      const edit = {
        id: this.detailCheckItem.id,
        check_item_use_yn: this.detailCheckItem.check_item_use_yn,
        check_item_rqrd_yn: this.detailCheckItem.check_item_rqrd_yn,
      };
      await base
        .updateCheckItem(edit)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            alert("점검 항목을 수정 했습니다.");
            this.$router.push("/menu/check-items/items");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getData() {
      base
        .getCheckItem(this.sessionId)
        .then((res) => {
          console.log(res);
          this.detailCheckItem = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
